@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: custom_black;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-Black.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-Black.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_black_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-BlackItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-BlackItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_bold;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-Bold.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-Bold.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_bold_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-BoldItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_extrabold;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-ExtraBold.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-ExtraBold.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_extrabold_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-ExtraBoldItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_extralight;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-ExtraLight.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-ExtraLight.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_extralight_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-ExtraLightItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-ExtraLightItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-Italic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-Italic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_light;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-Light.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-Light.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_light_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-LightItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-LightItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_medium;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-Medium.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-Medium.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_medium_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-MediumItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-MediumItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_regular;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-Regular.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_semibold;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-SemiBold.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-SemiBold.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_semibold_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-SemiBoldItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-SemiBoldItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_thin;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-Thin.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-Thin.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: custom_thin_italic;
  src: url('./assets/fonts/Montserrat/woff2/Montserrat-ThinItalic.woff2') format('woff2'),
  url('./assets/fonts/Montserrat/woff/Montserrat-ThinItalic.woff') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--scroll_bg);
}

::-webkit-scrollbar-thumb {
  background: var(--scroll_thumb);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scroll_thumb_highlighted);
}

@layer base {
  :root {
    --primary: #906551;
    --primary_light: #EFAF8A;
    --secondary: #ffffff;
    --tertiary: rgba(255, 255, 255, 0.15);
    --tertiary1: #BDACC5;
    --light: #A39999;
    --light_beige: #F1ECE3;
    --light_blue: rgba(139, 172, 187, 0.1);
    --dark_gray: rgba(6, 6, 6, 0.35);
    --toggle_color: rgba(94, 42, 195, 1);
    --error: #F2655C;

    --scroll_bg: #201630;
    --scroll_thumb: linear-gradient(278deg, #5723C2 -10.44%, #723FC5 100%);
    --scroll_thumb_highlighted: linear-gradient(278deg, #6431ca -10.44%, #8755d8 100%);

    --primary_gradient: linear-gradient(278deg, #5723C2 -10.44%, #723FC5 100%);
    --secondary_gradient: linear-gradient(to right, rgba(42, 29, 62, 0.45), rgba(31, 28, 54, 0.45));
    --calendar_gradient: linear-gradient(263deg, rgba(42, 29, 62, 0.45) 6.75%, rgba(14, 13, 29, 0.45) 98.86%);

    --bg_gradient_anim_stop_1: #191029;
    --bg_gradient_anim_stop_2: #130d1d;
    --bg_gradient_anim_stop_3: #0d0a14;
    --bg_gradient_anim_stop_4: #09060f;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-height {
  min-height: 92vh !important;
  min-height: 92dvh !important;
}

body {
  overflow-x: hidden !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-overflow-scrolling: touch;
  height: auto;
}

div {
  font-family: 'Montserrat', sans-serif;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.35);
  overflow: hidden !important;
}

.disabled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.13);
  color: #A39999;
  backdrop-filter: blur(6.550000190734863px);
  z-index: 998
}

.card_generic {
  background: linear-gradient(145deg, rgba(42, 29, 62, 0.45) 10.8%, rgba(14, 13, 29, 0.45) 70.45%);
  backdrop-filter: blur(12.5px);
}

.card_generic:not(.card_landing){
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.popup-background {
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(263deg, rgba(42, 29, 62, 1) 6.75%, rgba(14, 13, 29, 1) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.35);
}

.popup-position {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.image-mask{
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: 50%;
  -webkit-mask-image: radial-gradient(rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  mask-image: radial-gradient(rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.deep-purple-button {
  border-radius: 0.3125rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #2F2750;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
  margin-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
}

.full-width {
  width: -webkit-fill-available !important;
}

.cancel-button {
  background: linear-gradient(278deg, #F2655C -10.44%, #CA2D27 100%);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.not-selected {
  color: #5B5161;
}


.ngx-toastr {
  width: fit-content !important;
  font-size: 0.8em !important;
}

.toast-success {
  width: 25% !important;
  background-color: #0E4019 !important;
  stroke-width: 1px !important;
  stroke: rgba(48, 125, 36, 0.15) !important;
  box-shadow: 0px 0px 10px 0px rgba(76, 248, 114, 0.35) inset !important;
  backdrop-filter: blur(6.400000095367432px) !important;
}

.toast-error {
  width: 25% !important;
  border: 1px solid rgba(255, 255, 255, 0.15) !important;
  background-color: #521414 !important;
  stroke-width: 1px !important;
  stroke: rgba(250, 40, 40, 0.15) !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 140, 140, 0.35) inset !important;
  backdrop-filter: blur(6.400000095367432px) !important;
}

.toast-warning {
  width: 25% !important;
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  background-color: rgba(226, 135, 50, 0.45) !important;
  stroke: rgba(250, 140, 50, 0.25) !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 194, 77, 0.35) inset !important;
  backdrop-filter: blur(6.400000095367432px) !important;
}

.scroll-disabled {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.bg-dark_purple {
  background-color: #2D1533;
}

.bg-purple {
  background-color: #514363;
}


.text-brown {
  color: #B98D78;
}

.wrapper {
  position: relative;
  overflow: hidden;
}

.profile-icon-text {
  background: linear-gradient(141deg, #9880BE 38.93%, #7951B8 117.86%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1450px) {
  .text-2xs {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1400px) {
  .toast-success {
    width: 50% !important;
  }

  .toast-error {
    width: 50% !important;
  }

  .toast-warning {
    width: 50% !important;
  }
}

@media only screen and (min-width: 680px) and (max-width: 1200px) {
  .toast-success {
    width: 65% !important;
  }

  .toast-error {
    width: 65% !important;
  }

  .toast-warning {
    width: 65% !important;
  }
}

@media only screen and (min-width: 281px) and (max-width: 680px) {
  .toast-success {
    width: 85% !important;
  }

  .toast-error {
    width: 85% !important;
  }

  .toast-warning {
    width: 85% !important;
  }
}

@media only screen and (max-width: 280px) {
  .text {
    font-size: 0.7em;
  }

  .heading {
    font-size: 0.8em;
  }

  .mini-text {
    font-size: 0.55em;
  }

  .to-column {
    display: flex;
    flex-direction: column;
    gap: 0.7em;
    justify-content: flex-start;
  }

  .to-column-leading-0 {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    justify-content: flex-start;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-stretch {
    width: 100%;
  }

  .align-left-mobile {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .toast-success {
    width: 85% !important;
  }

  .toast-error {
    width: 85% !important;
  }

  .toast-warning {
    width: 85% !important;
  }

}
@media (min-width: 640px) {
  .reveal:not(.not-float){
    position: relative;
    transform: translateY(150px);
    opacity: 0;
  
    -webkit-transition: 1s ease;
    -ms-transition: 1s ease;
    transition: 1s ease;
  }
  
  .reveal.revealed{
    transform: translateY(0px);
    opacity: 1;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s;
  -webkit-text-fill-color: #fff !important;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.section::-webkit-scrollbar {
  width: 3px;
}

.section::-webkit-scrollbar-track {
  background-color: darkslategrey;
}

.section::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.9);
}

.popup-anim {
  animation: open-popup-anim;
  animation-duration: .35s;
  animation-timing-function: ease-in-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

@-moz-keyframes open-popup-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes open-popup-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes open-popup-anim{
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding-bottom: 2.35rem;
}

swiper-container::part(bullet-active) {
  background-color: #B33AD0;
  position: relative;
  border-radius: 100%;
}

swiper-container::part(bullet-active)::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  background-color: #c348e2;
  border-radius: 100%;
}

swiper-container::part(bullet) {
  background-color: rgba(184,59,215,0.75);
}

.generic-product-card {
  background-image: linear-gradient(181deg, rgba(34, 24, 51, 0.985) -8.14%, rgba(14, 13, 28, 0.985) 96.99%);
  box-shadow: -1px 4px 26.9px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255,255,255,0.15);
  position: relative;

  transition: background-image 0.75s ease-in-out, border 0.75s ease-in-out, outline .3s ease-in-out, box-shadow 0.75s ease-in-out, opacity 0.75s ease-in-out;
}

.generic-product-card img {
  opacity: 0.3;
  transition: opacity 0.75s ease-in-out;
}

.swiper-slide-active .generic-product-card {
  background-image: linear-gradient(181deg, rgba(52, 37, 77, 0.95) -8.14%, rgba(18, 16, 37, 0.95) 96.99%) !important;
  box-shadow: -1px 4px 26.9px 0px rgba(0, 0, 0, 0.25), inset -1px 4px 26.9px 0px rgba(204, 0, 255, 0.5);
  backdrop-filter: blur(12.5px);
  border: 1px solid rgba(255,255,255,0.25);
}

.swiper-slide-active .generic-product-card::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: inherit;
  border: 1px solid rgba(255, 255, 255, 1);
  outline: 2px solid #CC00FF;
  width: 100%;
  height: 100%;
  filter: blur(1px);
  z-index: -1;
}

.new-product > .generic-product-card {
  border: 1px solid rgba(255, 255, 255, 0.15);
  animation: pulse_1 3s infinite;
}

.swiper-slide-active .new-product > .generic-product-card::after {
  border: 1px solid rgba(204, 0, 255, 1);
  animation: pulse_2 3s infinite;
}

@keyframes pulse_2 {
  0% {
    border-color: rgba(204, 0, 255, 1);
    box-shadow: 0 0 0 0 rgba(204, 0, 255, 0.7);
  }
  50% {
    border-color: rgba(204, 0, 255, 0.3);
    box-shadow: 0 0 0 8px rgba(204, 0, 255, 0);
  }
  100% {
    border-color: rgba(204, 0, 255, 1);
    box-shadow: 0 0 0 0 rgba(204, 0, 255, 0);
  }
}

@keyframes pulse_1 {
  0% {
    border-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.15);
  }
  50% {
    border-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0);
  }
  100% {
    border-color: rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.swiper-slide-active .generic-product-card::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(./assets/images/blobl_decoration_selected.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 65%;
  height: 65%;
  filter: blur(35px);
  z-index: -1;
  opacity: 0.5;
}

.swiper-slide-active .generic-product-card img {
  opacity: 1 !important;
}

.generic-product-card .title-text{
  text-align: center;
  color: black;
  -webkit-text-stroke-width: 0.25;
  -webkit-text-stroke-color: #000;
  font: custom_bold;
  line-height: normal;
  text-transform: uppercase;

  background: linear-gradient(45deg, rgba(75, 68, 91, 1) -8.14%, rgba(65, 57, 86, 1) 96.99%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.5;

  transition: opacity 0.75s ease-in-out, background 0.75s ease-in-out;
}

.swiper-slide-active .generic-product-card .title-text {
  background: linear-gradient(45deg, rgba(103, 87, 126, 1) -8.14%, rgba(87, 70, 132, 1) 96.99%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;
}

.appear-anim{
  animation: appear-anim;
  animation-duration: .35s;
  animation-timing-function: ease-in-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  opacity: 0;
}

@-moz-keyframes appear-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes appear-anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appear-anim{
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flicker-anim {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.15;
  }

  50% {
    opacity: .85;
  }

  75% {
    opacity: 0.15;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flicker-anim {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.15;
  }

  50% {
    opacity: .85;
  }

  75% {
    opacity: 0.15;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flicker-anim{
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.15;
  }

  50% {
    opacity: .85;
  }

  75% {
    opacity: 0.15;
  }

  100% {
    opacity: 1;
  }
}

.flicker-anim_1 {
  animation: flicker-anim;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-delay: 2s;
}

.flicker-anim_2 {
  animation: flicker-anim;
  animation-duration: 8s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-delay: 4;
}

.flicker-anim_3 {
  animation: flicker-anim;
  animation-duration: 14s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-delay: 3s;
}

.flicker-anim_4 {
  animation: flicker-anim;
  animation-duration: 16s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 1;
  animation-delay: 5s;
}